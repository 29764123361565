import React, { useRef } from "react";
import imgUpload from "../assets/img/icon/imgupload.svg";

export const FileUploader = ({ handleFile }) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
    const handleChange = (event) => {
        const fileName = event.target.files[0];
        const fileUrl = URL.createObjectURL(event.target.files[0]);
        handleFile(fileName,fileUrl,event.target.files[0]);
    };
    return (
        <>
            {/* <span className="button-upload cursor-pointer" onClick={handleClick}>
                <img src={imgUpload} className="h-100" alt="" />
                Uploads
            </span> */}
            <input
                type="file" required
                onChange={handleChange}
                ref={hiddenFileInput} accept="image/*"
                // style={{ display: "none" }} // Make the file input element invisible
            />
            <div className="invalid-feedback">Please select a file.</div>
        </>
    );
};
