import React, { useState } from "react";
import { Modal } from "react-bootstrap";
// import { createDataNone } from "../../services/getService";
// import delIcon from "../../assets/img/icon/Del.svg";
import Loading from "../loading";

const Main = ({ dataId, dataImg, type, onRefresh, ...data }) => {

    const [isLoading, setIsLoading] = useState(false);


    // console.log(dataImg)

    return (
        <>
            <Modal {...data} className="modal-custom" size="md" backdrop="static" variant='white' keyboard={false} centered>
                {isLoading && <Loading border={true} />}
                {/* <Modal.Header closeButton>
                    <h5 className="modal-title"></h5>
                </Modal.Header> */}
                <Modal.Body className="p-0 position-relative">
                    <div className="row align-items-center text-start">
                        <div className="col-12 col-md-12 col-lg-12 p-0">
                            <div className="box-thankyou text-center">
                                <h1 className="text-white weight-bold">THANK YOU</h1>
                                <h2 className="text-white weight-medium">PAYMENT RECEIVED</h2>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main
