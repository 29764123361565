import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import axios from 'axios'

import Loading from "../layout/loading";
import { authUser, getUser } from '../services/authenService';
import { createData, getData, createDataPayment } from "../services/getService";

import Header from "../layout/header"
import { FileUploader } from "../components/FileUploader";
import ModalAlert from "../layout/modal/alertModal";

import banner from "../assets/img/banner-choose.jpg";
import bankicon from "../assets/img/kbank.jpg";
import calendar from "../assets/img/icon/Calendar.svg";
import mastercard from "../assets/img/icon/Mastercard.svg";
import qrcode from "../assets/img/icon/Qrcode.svg";
import markicon from "../assets/img/icon/Mark.svg";

import moment from 'moment';

const Main = () => {

    const [auth, setAuth] = useState(authUser())
    const [isLoading, setIsLoading] = useState(true);
    const [modalAlert, setModalAlert] = useState({ view: false, id: 0, type: '' })

    const [mark, setMark] = useState(1);

    const [validated, setValidated] = useState(false);

    const [memberData, setMemberData] = useState({});
    const [orderRef, setOrderRef] = useState();

    const [fileUpload, setFileUpload] = useState({ url: '', name: '', file: '' });

    const called = useRef(true)

    useEffect(() => {
        if (called.current) {
            Promise.all([fetchDataMember()]).then(res => {
                setIsLoading(false);
            });
        }
        return () => {
            called.current = false
        }
    }, [])

    const fetchDataMember = async () => {
        if (!auth || !auth.id)
            setTimeout(() => { window.location.reload() }, 500);

        getData('member', auth.id).then(
            response => {
                if (response.status === 'success') {
                    setMemberData(response.data)
                    if (response.data.unit === 'THB') {
                        setMark(2)
                    }
                }
            }).catch(e => {
                console.log(e.response.data.error)
            })
    }

    const handleFile = (fileName, fileUrl, file) => {
        console.log(file)
        setFileUpload({ url: fileUrl, name: fileName, file: file });
    };

    const buttonRef = useRef(null);


    const handleSubmit = async (event) => {

        event.preventDefault();
        const form = event.currentTarget;
        const dataForm = new FormData(event.target);

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            if (mark) {

                setIsLoading(true);

                dataForm.set('memberId', memberData.id);
                dataForm.set('country', memberData.country.id);
                dataForm.set('graduate', memberData.graduate);
                dataForm.set('member', memberData.member);
                dataForm.set('file', fileUpload.file);
                dataForm.set('paymentMethod', mark);
                dataForm.set('ratesId', memberData.rate && (memberData.rate.id));
                dataForm.set('equivalent', memberData.rate && (memberData.rate.exchange * memberData.price));

                await createData('order', dataForm).then(
                    response => {
                        if (response.status === 'success') {
                            
                            setOrderRef(response.data.orderRef)

                            if(mark === 1){
                                setIsLoading(true)
                                setTimeout(() => {
                                    buttonRef.current.click()
                                }, 2000);
                                
                            }else{
                                setModalAlert({ view: true })
                                setTimeout(() => {
                                    window.location.href = "/profile";
                                }, 3000);
                            }
                        }
                    }).catch(e => {
                        setIsLoading(false)
                        if(e.response.data.messages.errors === 'member'){
                            alert('Cannot apply again.');
                            window.location.href = "/profile";
                        }else{
                            console.log(e.response.data.messages.errors)
                        }                        
                    })
            } else {
                event.preventDefault();
                event.stopPropagation();
            }

        }
        setValidated(true);
    };

    // const axiosHeaderNoneB = axios.create({
    //     baseURL: '/',
    //     timeout: 0,
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     }
    // })

    // const handleSubmitPayment = async (event) => {
    //     event.preventDefault();
    //     const dataForm = new FormData(event.target);

    //     dataForm.set('merchantId', '950606072');
    //     dataForm.set('amount', '10.0');
    //     dataForm.set('orderRef', 'NO000000001');
    //     dataForm.set('currCode', '764');
    //     dataForm.set('successUrl', 'http://dev.eaea19payment.com/profile');
    //     dataForm.set('failUrl', 'http://dev.eaea19payment.com/choose');
    //     dataForm.set('cancelUrl', 'http://dev.eaea19payment.com/choose');
    //     dataForm.set('payType', 'N');
    //     dataForm.set('payMethod', 'CC');
    //     dataForm.set('lang', 'E');

    //     const urlApi = `https://uat.krungsrieasypay.com/BAY/eng/payment/payForm.jsp`

    //     await axiosHeaderNoneB.post(urlApi, dataForm).then(
    //         response => {
    //             console.log(response)
    //         }).catch(e => {
    //             console.log(e.response)
    //         })
    // };

    // console.log(mark)

    return (
        <>
            <div id="outer-container" style={{}}>
                <div className="wrapper bg-main min-vh-100" style={{ background: `#FFFFEC` }}>
                    {isLoading ? <Loading /> : null}
                    <section className="" id="page-wrap">
                        <div className="container-fluid ">
                            <Header page="home" />
                            <div className="row align-items-center justify-content-center text-start">
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="mt-4 text-center">
                                        <h1 className="text-green weight-bold">ORDER SUMMARY</h1>
                                    </div>
                                </div>
                                <div className="col-12 col-md-11 col-lg-12">
                                    <div className="mx-3 mx-md-5">
                                        <div className="my-3 my-md-5">
                                            <div className="row">
                                                <div className="col-12 col-md-7 col-lg-7">
                                                    <img src={banner} className="w-100" alt="" />
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-5">
                                                    <h2 className="text-green weight-bold mt-3 mt-md-0">EAEA2024</h2>
                                                    <h4 className="weight-medium">19th East Asian Economic Association International conference</h4>
                                                    <div className="mt-3">
                                                        <img src={calendar} className="" alt="" style={{ width: '30px', verticalAlign: 'middle' }} />
                                                        <span className="d-inline-block" style={{ verticalAlign: 'middle' }}><h3 className="weight-bold ms-2 mb-0">{moment().format('DD MMM YYYY')}</h3></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="my-5 mx-1 mx-md-5">
                                            <div className="row">
                                                <div className="col-6 col-md-6 col-lg-6">
                                                    <h2 className="weight-bold">Total (1 item)</h2>
                                                </div>
                                                <div className="col-6 col-md-6 col-lg-6 text-end">
                                                    <h2 className="text-green weight-bold d-inline-block ms-md-5 ps-md-5">{memberData.symbol}{memberData.price && memberData.price.toLocaleString()}</h2>
                                                    {memberData.unit === 'US' && <>
                                                        <h4><span>Equivalent to</span><span className="d-inline-block" style={{ width: '150px' }}>฿{memberData.rate && (memberData.rate.exchange * memberData.price).toLocaleString()}</span></h4>
                                                    </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="row justify-content-center mt-4">
                                                <div className="col-12 col-md-12 col-lg-12 mb-5">
                                                    <h2 className="text-green weight-bold">Payment</h2>
                                                    <h4 className="weight-bold">Choose payment method below</h4>
                                                    {memberData.unit === 'US' &&
                                                        <div>Note that this payment does not include any fees charged by your card issuer. Please check the final payment on your credit card bill.</div>
                                                    }
                                                </div>
                                            </div>

                                            {memberData.country && memberData.country.id === '218' ? <>
                                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                                    <div className="row justify-content-center mt-4">
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mb-4">
                                                                <h5 className="weight-medium">
                                                                    1.Please transfer payments to our bank accounts as detailed below
                                                                </h5>
                                                            </div>
                                                            <div className="d-inline-block">
                                                                <img src={bankicon} className="" alt="" style={{ width: '100px', verticalAlign: 'middle' }} />
                                                            </div>
                                                            <div className="d-inline-block ms-3">
                                                                <h5 className="weight-medium">
                                                                    <div className="mb-2"><b>Name of Account :</b>  THAMMASAT UNIVERSITY (EAEA19)</div>
                                                                    <div className="mb-2"><b>Account NO. :</b> 784-1-40424-2</div>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mt-5 mb-4">
                                                                <h5 className="weight-medium">
                                                                    2.Please attach the transfer slip/evidence in the following form
                                                                </h5>
                                                                <div className="mt-4">
                                                                    {/* <input id="file" type="file" onChange={handleFileChange} /> */}
                                                                    <FileUploader handleFile={handleFile} />
                                                                    {/* {fileUpload.url ?
                                                                        <>
                                                                            <div className="position-relative d-inline-block button-upload cursor-pointer">
                                                                                <img src={fileUpload.url} alt={fileUpload.name} className="w-25" style={{}} />
                                                                                <img src={imgBin} className="position-absolute cursor-pointer" onClick={(e) => setFileUpload({ url: '', name: '' })} style={{ bottom: '5px', right: '5px' }} />
                                                                            </div>
                                                                        </>
                                                                        : 
                                                                        <FileUploader handleFile={handleFile} />
                                                                        } */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mt-5 mb-5">
                                                                <h5 className="weight-medium">
                                                                    3.Our staff will confirm receipt within 3 office days.
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mt-md-4 mb-5 text-center" >
                                                                <button type="submit" className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>SUBMIT</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </> : <>
                                                <Form noValidate validated={validated} onSubmit={handleSubmit} className="">
                                                    <div className="row justify-content-center mt-4">
                                                        <div className="col-12 col-md-4 col-lg-3">
                                                            <div className={`box-payment text-center p-5 cursor-pointer mb-4 ${mark === 1 && "active"}`} onClick={() => setMark(1)}>
                                                                <div className="bb-mark"><img src={markicon} className="" alt="" style={{ height: '50px' }} /></div>
                                                                <div><img src={mastercard} className="" alt="" style={{ height: '60px' }} /></div>
                                                                <div className="mt-3"><h5>MasterCard / Visa / JCB </h5></div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mt-md-4 mb-5 text-center" >
                                                                <button type="submit" className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>CHECK OUT</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                                {/* <Form name="payFormCcard" className="d-none" id="payFormCcard" method="post" action="https://uat.krungsrieasypay.com/BAY/eng/payment/payForm.jsp">
                                                    <input type="hidden" name="merchantId" value="950606072" />                                                   
                                                    <input type="hidden" name="amount" value={memberData.rate && Number(memberData.rate.exchange * memberData.price).toFixed(2)} />
                                                    <input type="hidden" name="orderRef" value={orderRef} />
                                                    <input type="hidden" name="currCode" value="764" />
                                                    <input type="hidden" name="successUrl" value="https://dev.eaea19payment.com/successPayment" />
                                                    <input type="hidden" name="failUrl" value="https://dev.eaea19payment.com/failPayment" />
                                                    <input type="hidden" name="cancelUrl" value="https://dev.eaea19payment.com/choose" />
                                                    <input type="hidden" name="payType" value="N" />
                                                    <input type="hidden" name="payMethod" value="CC" />                                                   
                                                    <input type="hidden" name="lang" value="E" />
                                                    <div className="row justify-content-center mt-4 d-none">
                                                        <div className="col-12 col-md-4 col-lg-3">
                                                            <div className={`box-payment text-center p-5 cursor-pointer mb-4 ${mark === 1 && "active"}`} onClick={() => setMark(1)}>
                                                                <div className="bb-mark"><img src={markicon} className="" alt="" style={{ height: '50px' }} /></div>
                                                                <div><img src={mastercard} className="" alt="" style={{ height: '60px' }} /></div>
                                                                <div className="mt-3"><h5>MasterCard / Visa / JCB </h5></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mt-md-4 mb-5 text-center" >
                                                                <button type="submit" ref={buttonRef}  className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>CHECK OUT</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form> */}
                                                <Form name="payFormCcard" className="d-none" id="payFormCcard" method="post" action="https://www.krungsrieasypay.com/BAY/eng/payment/payForm.jsp">
                                                    <input type="hidden" name="merchantId" value="950217224" />
                                                    <input type="hidden" name="amount" value={memberData.rate && Number(memberData.rate.exchange * memberData.price).toFixed(2)} />                
                                                    {/* <input type="hidden" name="amount" value="1.00" />                 */}
                                                    <input type="hidden" name="orderRef" value={orderRef} />
                                                    <input type="hidden" name="currCode" value="764" />
                                                    <input type="hidden" name="successUrl" value="https://eaea19payment.com/successPayment" />
                                                    <input type="hidden" name="failUrl" value="https://eaea19payment.com/failPayment" />
                                                    <input type="hidden" name="cancelUrl" value="https://eaea19payment.com/choose" />
                                                    <input type="hidden" name="payType" value="N" />
                                                    <input type="hidden" name="payMethod" value="CC" />
                                                    <input type="hidden" name="lang" value="E" />
                                                    <div className="row justify-content-center mt-4">
                                                        <div className="col-12 col-md-4 col-lg-3">
                                                            <div className={`box-payment text-center p-5 cursor-pointer mb-4 ${mark === 1 && "active"}`} onClick={() => setMark(1)}>
                                                                <div className="bb-mark"><img src={markicon} className="" alt="" style={{ height: '50px' }} /></div>
                                                                <div><img src={mastercard} className="" alt="" style={{ height: '60px' }} /></div>
                                                                <div className="mt-3"><h5>MasterCard / Visa / JCB </h5></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-12 col-lg-12">
                                                            <div className="mt-md-4 mb-5 text-center" >
                                                                <button type="submit" ref={buttonRef}  className="btn btn-submit weight-bold d-inline-block mx-1" style={{ width: '150px' }}>CHECK OUT</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {
                modalAlert.view ?
                    <ModalAlert show={modalAlert.view}
                        dataId={modalAlert.id}
                        onHide={() => setModalAlert({ view: false })}
                    />
                    : null
            }
        </>
    );
};

export default Main;
