import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthMiddleware from "./components/authMiddleware";

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/button.scss";
import "./assets/scss/respornsive.scss";

import Home from "./pages/home";
import Login from "./pages/login";
import Signup from "./pages/signup";
import Choose from "./pages/chooseTopic";
import Checkout from "./pages/checkout";
import ConfirmPayment from "./pages/confirmPayment";
import Profile from "./pages/profile";
import Registrat from "./pages/registrat";
import Edit from "./pages/edit";

import SuccessPayment from "./pages/successPayment";
import FailPayment from "./pages/failPayment";
// import CancelPayment from "./pages/cancelPayment";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <BrowserRouter basename="">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/choose" element={<AuthMiddleware><Choose /></AuthMiddleware>} />
          <Route exact path="/checkout" element={<AuthMiddleware><Checkout /></AuthMiddleware>} />
          <Route exact path="/confirmPayment" element={<AuthMiddleware><ConfirmPayment /></AuthMiddleware>} />
          <Route exact path="/profile" element={<AuthMiddleware><Profile /></AuthMiddleware>} />
          <Route exact path="/registrat/:id" element={<AuthMiddleware><Registrat /></AuthMiddleware>} />
          <Route exact path="/edit" element={<AuthMiddleware><Edit /></AuthMiddleware>} />
          <Route exact path="/successPayment" element={<AuthMiddleware><SuccessPayment /></AuthMiddleware>} />
          <Route exact path="/failPayment" element={<AuthMiddleware><FailPayment /></AuthMiddleware>} />
          {/* <Route exact path="/cancelPayment" element={<AuthMiddleware><CancelPayment /></AuthMiddleware>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
